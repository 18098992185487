import { ThemeColors } from "../../interfaces/Theme";
import { addAlpha } from "../color-helpers";

const lightGrey = "#f3f5f9";
const primaryColor = "#073B88";
const primaryColorDark = "#322d35";
const teacher = "#FF8660";
const subject1 = "#71C9FA";
const subject2 = "#B1D366";
const white = "#ffffff";
const black = "#000000";

const navigation = "#E8EAEC";

const greyLighter = "#F5F5F5";
const greyLight = "#DBDBDB";

export const lightTheme: ThemeColors = {
    primaryColor: primaryColor,
    primaryColorDark: primaryColorDark,

    defaultBlack: black,
    defaultWhite: white,

    blockHeader: white,
    blockFooter: primaryColor,
    blockPage: white,
    contentPage: white,
    block: greyLighter,

    blockBorder: greyLight,

    blockItem1Default: white,
    blockItem1Selected: white,

    blockItem1SelectedTeacher: teacher,

    blockItem1SelectedSubject1: subject1,
    blockItem1SelectedSubject2: subject2,

    blockItem1BorderDefault: "#BAC4CF",
    blockItem1BorderSelected: "#FF8660",

    blockItem2Default: "#EEF0F2",

    textMainDefault: black,
    textMainOff: addAlpha(black, 4),
    textMainDisabled: addAlpha(black, 2),
    textMainInverted: white,

    elementGrey10: addAlpha(black, 1),
    elementGrey20: addAlpha(black, 2),
    elementGrey50: addAlpha(black, 5),
    elementGrey80: addAlpha(black, 8),

    textH1ContentPageDefault: black,

    textHeaderDefault: black,
    textFooterDefault: white,

    textSubject1Default: black,
    textSubject1Off: addAlpha(black, 4),
    textSubject1Disabled: addAlpha(black, 2),

    textSubject2Default: black,
    textSubject2Off: addAlpha(black, 4),
    textSubject2Disabled: addAlpha(black, 2),

    elementEvidencebDefault: "#AC9BFF",
    elementEvidencebDefaultLight: "#D9D1FF",
    elementEvidencebHover: white,
    elementEvidencebDisabled: addAlpha("#AC9BFF", 4),
    elementEvidencebIdle: addAlpha(black, 2),
    elementEvidencebUnfocused: addAlpha(white, 2),

    elementTeacherDefault: teacher,
    elementTeacherHover: white,
    elementTeacherDisabled: addAlpha(teacher, 4),
    elementTeacherIdle: addAlpha(black, 2),
    elementTeacherUnfocused: addAlpha(white, 2),

    elementSubject1Default: subject1,
    elementSubject1Hover: white,
    elementSubject1Disabled: addAlpha(subject1, 4),
    elementSubject1Idle: addAlpha(black, 2),
    elementSubject1Unfocused: addAlpha(white, 2),

    elementSubject2Default: subject2,
    elementSubject2Hover: white,
    elementSubject2Disabled: addAlpha(subject2, 4),
    elementSubject2Idle: addAlpha(black, 2),
    elementSubject2Unfocused: addAlpha(white, 2),

    elementNavigationDefault: navigation,
    elementNavigationHover: white,
    elementNavigationDisabled: addAlpha(primaryColor, 4),

    elementButtonPrimaryDefault: primaryColor,
    elementButtonPrimaryBorderDefault: primaryColor,
    elementButtonPrimaryTextDefault: white,
    elementButtonPrimaryHover: white,
    elementButtonPrimaryBorderHover: primaryColor,
    elementButtonPrimaryTextHover: primaryColor,

    elementButtonSecondaryDefault: "transparent",
    elementButtonSecondaryBorderDefault: navigation,
    elementButtonSecondaryTextDefault: navigation,
    elementButtonSecondaryHover: white,
    elementButtonSecondaryBorderHover: navigation,
    elementButtonSecondaryTextHover: navigation,

    elementButtonCustomHover: "#323232",

    elementTabPrimaryActiveTextDefault: white,
    elementTabPrimaryActiveTextHover: white,

    elementTabPrimaryActiveBorderDefault: "black",

    elementTabPrimaryInactiveDefault: white,
    elementTabPrimaryInactiveHover: "#F5F5F5",

    elementTabPrimaryInactiveTextDefault: addAlpha(black, 8),
    elementTabPrimaryInactiveTextHover: addAlpha(black, 8),

    elementTabPrimaryInactiveBorderDefault: addAlpha(black, 5),
    elementTabPrimaryInactiveBorderHover: addAlpha(black, 5),

    elementBackgroundAvatar: black,

    elementObjectiveBackgroundCompleted: primaryColor,
    elementObjectiveBackgroundInProgress: addAlpha(primaryColor, 6),
    elementObjectiveBackgroundNotExplored: "#AEAEAE",

    alertCorrect: "#2A7D2B",
    alertNeutral: primaryColor,
    alertFailure: "#A41A0E",

    alertUp: "#4EADF2",
    alertDown: "#FF60BA",

    ressourcesFillSolo: "#0765E0",
    ressourcesFillDuo: "#5855E5",
    ressourcesFillPlaylist: "#E09452",
    ressourcesFillTuto: "#34A762",
    ressourcesFillWorkshop: "#C85757",

    thumbnailMediaIllustrationFillNeutral: addAlpha(black, 3),
    thumbnailMediaIllustrationFillHover: lightGrey,

    thumbnailMediaStrokeNeutral: addAlpha(white, 1),
    thumbnailMediaStrokeHover: addAlpha(white, 1),

    elementSubject1DefaultDarkBg: "rgba(255, 255, 255, 0.8)",
    elementSubject1DisabledDarkBg: "rgba(255, 255, 255, 0.3)",
    elementSubject2DefaultDarkBg: "rgba(255, 255, 255, 0.8)",
    elementSubject2DisabledDarkBg: "rgba(255, 255, 255, 0.3)",
};
