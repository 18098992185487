/**
 * Versions of the app that should fetch from athena-resources instead (new system) of content-
 * access (which is being deprecated)
 */
export const VERSION_PREFIXES_FOR_NEW_RESOURCE_SYSTEM = [
    "athena",
    "admath/men",
    "admath/sandbox-en",
    "adlatin/atticus",
    "adlatin/my-atticus",
    "adlatin/atticus-scuola",
    "adlang/idfLGT",
    "adlang/idfpro",
    "adlang/specimen-idfpro",
    "adfrac",
    "adlatin/espf1",
    "adlatin/espf2",
];
