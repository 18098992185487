import React, { useContext } from "react";
import {
    Control,
    SegmentedControl,
} from "../../design-system-components/SegmentedControl/SegmentedControl";
import { sessionStore } from "../../contexts/SessionContext";
import { useActiveSubject, useSubjects } from "../../hooks/useActiveSubject";
import { commonMessages } from "../../utils/messages";
import Case from "case";

export type SubjectSwitchProps = {
    onSwitch?: () => void;
    className?: string;
};

export const SubjectSwitch = ({ onSwitch, className }: SubjectSwitchProps) => {
    const { setSession } = useContext(sessionStore);
    const subjects = useSubjects();
    const activeSubject = useActiveSubject();

    return (
        <>
            {subjects.length > 1 && (
                <SegmentedControl
                    defaultValue={activeSubject.name}
                    onChange={(value: string) => {
                        setSession((curr) => ({
                            ...curr,
                            extra: {
                                ...curr.extra,
                                activeSubject: subjects.find(
                                    (subject) => subject.name === value
                                )!.id,
                            },
                        }));
                        if (onSwitch) onSwitch();
                    }}
                    aria-label={commonMessages.subject}
                    color={activeSubject.colors.default}
                    className={className}
                >
                    {subjects.map((subject) => (
                        <Control value={subject.name} key={subject.name}>
                            {Case.pascal(subject.name)}
                        </Control>
                    ))}
                </SegmentedControl>
            )}
        </>
    );
};
